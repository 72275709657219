import React from "react"
import { graphql } from "gatsby"
import Guides from "../../components/page/guides"
import Card from "../../components/page/card"

export const pageQuery = graphql`
  query {
    allMdx (filter: {frontmatter: {cate: {in: "yuanli"}}}) {
      edges {
        node {
          id
          frontmatter {
            cate
            card_title
            date(formatString: "YYYY MMMM Do", locale:"zh-cn")
            path
            title
            thumbnail {
              childImageSharp {
                fluid(maxHeight: 180) {
                  ...GatsbyImageSharpFluid
                }
              }
              extension
              publicURL
            }
            author {
              name
                profilepicture {
                  childImageSharp{
                    fixed(width: 30) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
        }
      }
    }
  }
`

const Research = ({
    data: {
        allMdx: { edges },
      },
})=>{
    return(
        <Guides title="翻墙原理简介 - 翻墙101" >
            <ol className="guides-cards">
                {edges.map((edge)=><Card data={edge.node.frontmatter} />)}
            </ol>
        </Guides>
    )
}

export default Research