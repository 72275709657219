import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Layout from "../layout"

const Guides = ({ children, title })  => {
  const url = typeof window !== 'undefined' ? window.location.href : '';
    const data = useStaticQuery(graphql`
    query {
      allMdx (filter: {frontmatter: {cate: {in: ["guide"]}}}) {
        edges {
          node {
            id
            excerpt(pruneLength: 250)
            frontmatter {
              cate
              date(formatString: "MMMM Do, YYYY", locale:"zh-cn")
              path
              title
              thumbnail {
                childImageSharp {
                  fluid(maxHeight: 200) {
                    ...GatsbyImageSharpFluid
                  }
                }
                extension
                publicURL
              }
              intro
            }
          }
        }
      }
    }
  `)
    return(
        <Layout title={title}>
          <div className="guides">
            <header className="guides-title" >
              <div className="container">
                <h1 className="guides-title-h2">翻墙指南</h1>
                <ul className="guides-nav">
                  {/* <li className="guides-nav-li" >
                    <Link className="guides-nav-a" to="/guides/"
                      activeClassName="active"
                    >所有文章</Link>
                  </li> */}
                  <li className="guides-nav-li" >
                    <Link className={"guides-nav-a" + ( !url.includes("tutorial") && !url.includes("report") && !url.includes("yuanli") ? " active" : "") } to="/guides/#zhinan/"
                    >最新研究</Link>
                  </li>
                  <li className="guides-nav-li" >
                    <Link className={"guides-nav-a" + ( url.includes("report") ? " active" : "") }  to="/guides/report/#zhinan/"
                    >翻墙报告</Link>
                  </li>
                  <li className="guides-nav-li" >
                    <Link className={"guides-nav-a" + ( url.includes("tutorial") ? " active" : "") }  to="/guides/tutorial/#zhinan/"
                    >教程</Link>
                  </li>
                  <li className="guides-nav-li" >
                    <Link className={"guides-nav-a" + ( url.includes("yuanli") ? " active" : "") }  to="/guides/yuanli/#zhinan/"
                    >翻墙原理</Link>
                  </li>
                </ul>
              </div>
            </header>
            <div className="guides-content container">
              {children}
            </div>
          </div>
        </Layout>
    )
}

export default Guides;