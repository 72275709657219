import React from "react"
import { Link } from "gatsby"
import Image from "../image"

const Card = ({data}) => {
    return (
        <li className="guides-card" >
            <Link to={data.path} className="guides-card-a">
                <div className="guides-card-thumbnail" >
                    <Image className="guides-card-img" src={data.thumbnail.childImageSharp} url={data.thumbnail.publicURL} alt={data.title+"缩略图"}/>
                </div>
                <div className="guides-card-text" >
                    <h2 className="guides-card-text-h2">{data.card_title !== null ? data.card_title : data.title}</h2>
                    <div className="guides-card-bottom">
                        <div className="guides-card-author">
                            <Image src={data.author.profilepicture.childImageSharp} alt={data.author.name+"照片"} className="guides-card-avatar"/>
                        </div>
                        <div className="guides-card-infos" >
                            <span className="guides-card-info" ><strong className="guides-card-info-name">{data.author.name}</strong></span>
                            <span className="guides-card-info-time" >
                                <time>{data.date}</time> 更新
                            </span>
                        </div>
                    </div>
                </div>
            </Link>
        </li>
    )
}

export default Card